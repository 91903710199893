/*
author: Gregsterius (https://sketchfab.com/Gregsterius)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/3d-printer-0e825a8d48b14bee883a131fe029d4fc
title: 3D Printer
*/

import * as THREE from 'three'
import React, { useRef, Suspense, useEffect } from 'react'
import { useGLTF, OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import MoreVertIcon from '@mui/icons-material/MoreVert';

import usePrinterPosition from '../../../../hooks/usePrinterPosition'
import useStore from '../../../../zustand/useStore'

function Model({ ...props }) {
  const BED_SIZE_X = 220
  const BED_SIZE_Y = 220
  const BED_SIZE_Z = 250

  const bed = useRef()      // == y_position 
  const boat = useRef()     // == y_position 
  const nozzle = useRef()   // == x_position
  const upDown = useRef()   // == z_position
  const clipConstant = useRef()

  const [x, y, z] = usePrinterPosition()

  const productEnabled = useStore(state => state.productEnabled)

  // Returns true if setModelPosition is safe
  function canSetModelPosition() {
    return bed.current !== null && bed.current !== undefined
      && nozzle.current !== null && nozzle.current !== undefined
      && upDown.current !== null && nozzle.current !== undefined
  }

  // Side effect: updates model's position
  function setModelPosition(x, y, z) {
    if (!canSetModelPosition()) {
      return
    }

    bed.current.position.z = (y / BED_SIZE_Y * 18.15 - 9.55)
    nozzle.current.position.x = (x / BED_SIZE_X * 14.42 - 12.27)
    nozzle.current.position.y = (z / BED_SIZE_Z * 13.645)
    upDown.current.position.y = (z / BED_SIZE_Z * 13.62 + 9.47)

    // Clip plane in range [-.535, .425]
    // Map z = [0, 88] onto this range
    // I don't know where any of these numbers come from but they work :)
    clipConstant.current = (z / 91.66666667) - .535

    // Update boat position
    if (boat.current) {
      boat.current.position.z = (y / BED_SIZE_Y * 18.15 - 9.55)
    }
  } 

  // Called everytime the position changes
  useEffect(() => {
    setModelPosition(x, y, z)
  }, [x, y, z])

  const { nodes, materials } = useGLTF('/Models/printer3d.gltf')
  const boatModel = useGLTF('/Models/boat.glb')
  const boatMesh = boatModel.nodes["3DBenchy"]

  return (
    <group {...props} dispose={null} scale={.2} position={[0,-2,-1]}>
      {productEnabled && (
          <mesh ref={boat} geometry={boatMesh.geometry} position={[0, 7.33, 0]} scale={0.1} castShadow>
            {/* He's the king! */}
            <meshPhongMaterial color="#dedede" shininess={100} side={THREE.DoubleSide} clipShadows>
              <plane attach="clippingPlanes-0" normal={[0, -1, 0]} constant={clipConstant.current} />
            </meshPhongMaterial>
          </mesh>
      )}
      <mesh geometry={nodes.upDownCoil.geometry} material={materials.UPDOWN6} position={[0, 14.87, -1.01]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials.UPDOWN3} position={[4.03, 5.15, -11.37]} />
      <mesh geometry={nodes.Bolts_cyl.geometry} material={nodes.Bolts_cyl.material} position={[-4.02, 4.05, -11.37]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.UPDOWN3} position={[-4.02, 5.15, -11.37]} />
      <mesh geometry={nodes.Stand2.geometry} material={materials.UPDOWN3} position={[0, 3.06, 0]} />
      <mesh geometry={nodes.Stand1.geometry} material={materials.UPDOWN3} position={[-10.96, 3.06, 0.19]} />
      <mesh geometry={nodes.Base.geometry} material={materials.UPDOWN3} position={[0, 3.06, 0]} />
      <group position={[0, 2.23, 12.22]} rotation={[-0.44, 0, -Math.PI]} scale={[-2.21, -1.4, -0.11]}>
        <mesh geometry={nodes.Cube029.geometry} material={materials.UPDOWN1} />
        <mesh geometry={nodes.Cube029_1.geometry} material={materials.Display} />
      </group>
      <mesh geometry={nodes.Legs.geometry} material={materials.UPDOWN1} position={[0, -0.15, 0.64]} />
      <mesh geometry={nodes.Bolts_cyl001.geometry} material={materials.UPDOWN1} position={[0, 5.92, 0]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials.UPDOWN6} position={[4.03, 4.96, -0.49]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials.UPDOWN6} position={[-4.02, 4.96, -0.49]} />
      <mesh geometry={nodes.Cylinder036.geometry} material={materials.UPDOWN3} position={[9.97, 1.48, -2.58]} rotation={[Math.PI / 2, 0, 0]} scale={0.36} />
      <mesh geometry={nodes.Cylinder021.geometry} material={materials.UPDOWN1} position={[0, 7.12, 0]} scale={1.67} />
      <mesh geometry={nodes.Cylinder017.geometry} material={materials.UPDOWN2} position={[0, 4.71, -9.19]} scale={[1.3, 1.08, 1.3]} />
      <mesh geometry={nodes.Cylinder016.geometry} material={materials.UPDOWN1} position={[0, 4.04, -9.19]} scale={1.47} />
      <mesh geometry={nodes.Bolts_cyl002.geometry} material={materials.UPDOWN1} position={[0, 4.08, -9.79]} />
      <mesh geometry={nodes.Cylinder015.geometry} material={materials.UPDOWN3} position={[0, 5.11, -9.19]} scale={[0.29, 0.15, 0.29]} />
      <mesh geometry={nodes.Cylinder012.geometry} material={materials.UPDOWN1} position={[0, 4.94, -9.19]} />
      <mesh geometry={nodes.Cylinder010.geometry} material={materials.UPDOWN3} position={[0, 4.2, 8.59]} scale={[0.29, 0.15, 0.29]} />
      <mesh geometry={nodes.Cylinder006.geometry} material={materials.UPDOWN1} position={[0, 4.37, 8.59]} scale={1.3} />
      <mesh geometry={nodes.Cylinder009.geometry} material={materials.UPDOWN1} position={[0, 4.46, 8.59]} />
      <mesh geometry={nodes.Cylinder008.geometry} material={materials.UPDOWN1} position={[0, 4.94, 8.59]} />
      <mesh geometry={nodes.Cylinder014.geometry} material={materials.UPDOWN3} position={[0, 4.37, -9.19]} scale={1.3} />
      <mesh geometry={nodes.Cylinder013.geometry} material={materials.UPDOWN1} position={[0, 4.46, -9.19]} />
      <mesh geometry={nodes.Cylinder011.geometry} material={nodes.Cylinder011.material} position={[0, 4.71, -9.19]} scale={[1.08, 1.19, 1.08]} />
      <mesh geometry={nodes.Cylinder007.geometry} material={nodes.Cylinder007.material} position={[0, 4.71, 8.59]} scale={[1.08, 1.19, 1.08]} />
      <mesh geometry={nodes.Cylinder020.geometry} material={materials.UPDOWN1} position={[0, 7.12, -0.47]} />
      <mesh geometry={nodes.StandRods.geometry} material={materials.UPDOWN6} position={[0, 7.12, 0]} />
      <group ref={nozzle}>
        <mesh geometry={nodes.nozzl4.geometry} material={materials.UPDOWN7} position={[5.15, 7.38, -3.06]} scale={0.084} />
                                                                              {/* x: 7.15 to -7.27, y: 7.38 to 21.025*/}
      </group>
      <group ref={upDown} position={[-8.61, 9.48, -0.98]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} scale={0.72}>
                        {/*y: 9.48 to 23.09 */}
        <mesh geometry={nodes.Cylinder038.geometry} material={materials.UPDOWN1} />
        <mesh geometry={nodes.Cylinder038_1.geometry} material={materials.UPDOWN2} />
        <mesh geometry={nodes.Cylinder038_2.geometry} material={materials.UPDOWN3} />
        <mesh geometry={nodes.Cylinder038_3.geometry} material={materials.UPDOWN4} />
        <mesh geometry={nodes.Cylinder038_4.geometry} material={materials.UPDOWN5} />
        <mesh geometry={nodes.Cylinder038_5.geometry} material={materials.UPDOWN6} />
        <mesh geometry={nodes.Cylinder038_6.geometry} material={materials.UPDOWN7} />
      </group>
        <mesh ref={bed} geometry={nodes.Cube003.geometry} material={materials['Black base bed']} position={[0.06, 7.12, -4.05]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1, 1.23, 1.02]} />
                                                                                              {/* 8.6 to -9.55 */}
    </group>
  )
}

const Printer = ( () => {
  return (
    <>
      <div className='comp__header'>
        <p>3D Twin View</p>
        <MoreVertIcon className='icon more__icon'/>
      </div>
      <div className='printer__canvas'>
        <Canvas gl={{ localClippingEnabled: true }}>
          <Suspense fallback={null}>
            <ambientLight />
            <spotLight intensity={0.9} angle={0.1} penumbra={1}
              position={[10,15,10]} castShadow />

            <Model />
            <OrbitControls 
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
            />
          </Suspense>
        </Canvas>
      </div>
    </>
  )
});
  
export default Printer
