import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import "./sass/main.scss";

// Page Components
import LoginPage from "./pages/LandingPage/LoginPage";
import Page404 from "./pages/404Page/Page404"
import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from './pages/ProfilePage/ProfilePage'
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import Blender from "./pages/Dashboards/Blender/View";
import Printer3D from "./pages/Dashboards/Printer3D/View";
import EEG from "./pages/Dashboards/EEG/View";

import { Account } from "./context/Account";

function App() {
  return (
    <Account>
      <Routes>
        
        <Route exact path='/' element={<LoginPage/>} />
        <Route path='/home' element={<HomePage/>} />
        <Route path='/profile' element={<ProfilePage/>} />
        <Route path='/notification' element={<NotificationPage/>} />
        <Route path='/dashboard/blender/:machineID' element={<Blender/>} />
        <Route path='/dashboard/3dprinter/:machineID' element={<Printer3D/>} />
        <Route path='/dashboard/eeg/:machineID' element={<EEG/>} />

        <Route path="*" element={<Page404/>} />
      </Routes>
    </Account>
  );
}

export default App;
