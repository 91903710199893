// landing page's rotating model of a console

import React, {useRef, Suspense} from 'react'
import { Canvas, useFrame} from '@react-three/fiber'
import {OrbitControls, useGLTF} from '@react-three/drei'

function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/Models/hologram_console/scene.gltf')
  useFrame (() => (group.current.rotation.y += 0.005))
  return (
    <group ref={group} {...props} dispose={null} scale={2}>
      <group rotation={[-Math.PI / 2, 0, 2.5]} scale={1.49}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.defaultMaterial.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_1.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_2.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_3.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_4.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_5.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_6.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_7.geometry} material={materials.MAT_Monitors02} />
          <mesh geometry={nodes.defaultMaterial_8.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_9.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_10.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_11.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_12.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_13.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_14.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_15.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_16.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_17.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_18.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_19.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_20.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_21.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_22.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_23.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_24.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_25.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_26.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_27.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_28.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_29.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_30.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_31.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_32.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_33.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_34.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_35.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_36.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_37.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_38.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_39.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_40.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_41.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_42.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_43.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_44.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_45.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_46.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_47.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_48.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_49.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_50.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_51.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_52.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_53.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_54.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_55.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_56.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_57.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_58.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_59.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_60.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_61.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_62.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_63.geometry} material={materials.MAT_Monitors01} />
          <mesh geometry={nodes.defaultMaterial_64.geometry} material={materials.MAT_Monitors01} />
        </group>
      </group>
    </group>
  )
}

function Console() {

  return (
    <div className='console__obj'>
    {/* <div> */}
      <Canvas>
        <Suspense fallback={null}>
          <ambientLight />
          <spotLight intensity={0.9} angle={0.1} penumbra={1}
            position={[11,15,10]} castShadow />
          <Model />
          <OrbitControls 
            enablePan={true}
            enableZoom={true}
            enableRotate={true}
          />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default Console