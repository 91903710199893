// default header used in most pages

import React from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import {Link} from "react-router-dom";
import useStore from '../../zustand/useStore';

function Header() {
    const wsConnState = useStore(state => state.wsConnState);

  return (
    // <>
        <div className='header'>
            <Link to='/' className='header__title'>
                <img className="header__logo" src={process.env.PUBLIC_URL + `/images/logo.png`} alt="logo"/>
            </Link>
            <div className='header__nav'>
                <div className='header__status'>
                    {{
                        0: '🟡', //CONNECTING
                        1: '🟢', //OPEN
                        2: '⭕', //CLOSING
                        3: '🔴', //CLOSED
                    }[wsConnState]}
                </div>
                <Link to='/home' className='header__icon'> 
                    <GridViewIcon />
                </Link>
                
                <Link to='/notification' className='header__icon'> 
                    <NotificationsOutlinedIcon />
                </Link>

                <div onClick={() => console.log('clicked')} className='header__icon' > 
                    <HelpOutlineOutlinedIcon /> 
                </div>
                {/* <div onClick={() => (settingModal ?
                              closeModal()
                              : openModal())}
                               className='header__icon'> 
                    <SettingsOutlinedIcon />
                </div> */}
                <Link to='/profile' className='header__icon'> 
                    <PortraitOutlinedIcon />
                </Link>
            </div>
            
        </div>
    
//         {loggedIn && 
//         (
//         <div className='header'>
//             <Link to='/' className='header__title'>
//                 <img className="header__logo" src={process.env.PUBLIC_URL + `/images/logo.png`} alt="logo"/>
//             </Link>

//             {machine ? (
//                 <div className='header__nav'>
//                     <div>
//                         {{
//                             0: '🟡', //CONNECTING
//                             1: '🟢', //OPEN
//                             2: '⭕', //CLOSING
//                             3: '🔴', //CLOSED
//                         }[wsConnState]}
//                     </div>
//                 <Link to='/home' className='header__icon'> 
//                     <GridViewIcon />
//                 </Link>

                
//                 <div onClick={() => console.log('clicked')} className='header__icon' > 
//                     <NotificationsOutlinedIcon />
//                 </div>

//                 <div onClick={() => console.log('clicked')} className='header__icon' > 
//                 <   HelpOutlineOutlinedIcon /> 
//                 </div>

//                           <div onClick={() => (settingModal ?
//                               closeModal()
//                               : openModal())}
//                                className='header__icon'> 
//                     <SettingsOutlinedIcon />
//                 </div>

//                 <Link to='/profile' className='header__icon'> 
//                     <PortraitOutlinedIcon />
//                 </Link>
//             </div>
//             )
//             :
//             <div className='header__nav'>
//                 <Link to='/home' className='header__icon'> 
//                     <GridViewIcon />
//                 </Link>

//                 <Link to='/profile' className='header__icon'> 
//                     <PortraitOutlinedIcon />
//                 </Link>
//             </div>
//         }
//         </div>
//     )}
//   </>
)}

// const Header = () => {
//   return (
//     <div className='header'>
//       <div className='header-inner'>
        
//         <div className='logo'>
//           <Link to={"/home"}>
//             Community Zer0
//           </Link>
//         </div>

//         <div className='header-login'>
//         <Link to={"/profile"}>
//           Profile
//         </Link>

//         </div>
//       </div>
//     </div>
//   );
// };

export default Header;