import React, { useState, useContext } from 'react'
import { Navigate } from "react-router-dom";


// Componenets
import { AccountContext } from '../../context/Account';
import ChangePassword from './components/ChangePassword';
import ChangeEmail from './components/ChangeEmail';
import Header from '../GlobalComponents/Header';
import Status from '../../context/Status'


function ProfilePage() {
    const {logout} = useContext(AccountContext);
    const [loggedIn, setLogin] = useState(JSON.parse(localStorage.getItem('is-login')) || false);
    
    function logoff(){
      logout();
      localStorage.setItem('is-login', JSON.stringify(false));
      setLogin(false);
    }
    
    
  return (
    <>
      <Header />
      <div className='generic__page'>
        <div className='header__div'>
            <span className='available__header'>
                Profile Page
            </span>
      <Status />
        </div>

        <div className='profile'>
          
          <div className='page__margin'>
    
            {!loggedIn && (
              <Navigate to="/" replace />)
            }
            <>
              {/* <Status /> */}
              <button className='plain__btn' onClick={logoff}>Logout</button>
    
              <ChangePassword />
              <ChangeEmail />
            </>
          </div>
        </div>
      </div>
    </>
    )
}

export default ProfilePage