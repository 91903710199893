// Change user's password through aws cognito


import React, {useState, useContext} from 'react'
import { AccountContext } from '../../../context/Account';

export default () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const {getSession} = useContext(AccountContext);

    const onSubmit = (event) => {
        event.preventDefault();

        getSession().then(({user}) => {
            user.changePassword(password, newPassword, (err, result) => {
                if (err) {
                    console.error(err);
                } else {
                    console.log(result);
                }
            });
        });
    };

    return (
        <div>
            <h1>Change Password</h1>
            <form onSubmit={onSubmit}>
                <input 
                    type="text"
                    placeholder="Current Password"
                    value={password} 
                    onChange={(event) => setPassword(event.target.value)}
                />
                <input 
                    type="text"
                    value={newPassword} 
                    placeholder="New Password"
                    onChange={(event) => setNewPassword(event.target.value)}
                />

                <button className='plain__btn' type='submit'>Change Password</button>
            </form>
        </div>
    )
}
