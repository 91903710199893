// Display the x, y, z positions

import React from 'react'

// Zustand
import useStore from '../../../zustand/useStore';

function Position() {
    // Position data from useStore
    const x_absolute_position = useStore(state => state.x_absolute_position);
    const y_absolute_position = useStore(state => state.y_absolute_position);
    const z_absolute_position = useStore(state => state.z_absolute_position);
    const x_extruder = useStore(state => state.x_extruder);
    const y_extruder = useStore(state => state.y_extruder);
    const z_extruder = useStore(state => state.z_extruder);
    const x_bedAcc = useStore(state => state.x_bedAcc);
    const y_bedAcc = useStore(state => state.y_bedAcc);
    const z_bedAcc = useStore(state => state.z_bedAcc);
    
    const lastUpdateTime = useStore(state => state.lastUpdateTime);

    
    
    return (
        <div className='position'>
            <div className='position__table'>
                <span>
                    <p>Position</p>
                    <p>Absolute (mm)</p>
                    <p>Extuder Accelerometer (m/s^2)</p>
                    <p>Bed Accelerometer (m/s^2)</p>
                </span>

                <span>
                    <p>X</p>
                        <p>{parseFloat(x_absolute_position).toFixed(2)}</p>
                    <p>{x_extruder}</p>
                    <p>{x_bedAcc}</p>
                </span>
                <span>
                    <p>Y</p>
                    <p>{parseFloat(y_absolute_position).toFixed(2)}</p>
                    <p>{y_extruder}</p>
                    <p>{y_bedAcc}</p>
                </span>
                <span>
                    <p>Z</p>
                    <p>{parseFloat(z_absolute_position).toFixed(2)}</p>
                    <p>{z_extruder}</p>
                    <p>{z_bedAcc}</p>
                </span>

            </div>
            <p style={{fontSize:11, float:'right'}}>Last Updated Time: { lastUpdateTime }</p>
        </div>
    )
}
    

export default Position