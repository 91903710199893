// Change user's email through aws cognito

import React, {useState, useContext} from 'react'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AccountContext } from '../../../context/Account';

export default () => {
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');

    const {getSession, authenticate} = useContext(AccountContext);

    const onSubmit = (event) => {
        event.preventDefault();
        
        getSession().then(({user, email}) => {
            authenticate(email, password).then(() => {
                console.log(attributes)
                const attributes = [
                    new CognitoUserAttribute({Name: 'email', Value: newEmail})
                ];

                user.updateAttributes(attributes, (err, results) => {
                    if (err) {
                        console.error(err);
                    } else {
                        console.log(results);
                    }
                })
            })
        });
    };

    return (
        <div className='login__container'>
            <h1>Change Email</h1>

            <form onSubmit={onSubmit}>

                <input 
                    type="text"
                    value={newEmail} 
                    placeholder='New Email'
                    onChange={(event) => setNewEmail(event.target.value)}
                />
                
                <input 
                    type="text"
                    value={password} 
                    placeholder="Current Password"
                    onChange={(event) => setPassword(event.target.value)}
                />

                <button className='plain__btn' type='submit'>Change Email</button>
            </form>
        </div>
    )
}
