// EEG Dashboard UI composed of components from /Componenets


import React, {useContext, useState, useEffect} from 'react';
import { Navigate, useParams } from 'react-router-dom'

// Account info
import { AccountContext } from '../../../context/Account';

// components
import Header from '../../GlobalComponents/Header'
import SensorChart from '../Components/SensorChart';

// Zustand state
import useStore from '../../../zustand/useStore'

// WS Util
import { WSUtil } from '../../../utils/wsUtil'
import EEGHeatmap from '../Components/EEGHeatmap';


function EEG() {
  
  // User account info
  const loggedIn = JSON.parse(localStorage.getItem('is-login')) || false;
  const { machineID } = useParams();
  const [role, setRole] = useState("Viewer");
  const { userSub } = useContext(AccountContext);

  const eegMetrics = [
    "CH1", "CH2", "CH3", "CH4",
    "CH5", "CH6", "CH7", "CH8",
    "CH9", "CH10", "CH11", "CH12",
    "CH13", "CH14", "CH15", "CH16",
  ];
  const moodMetrics = [
    "Emotion", "Fatigue", "Focus", "Relaxation", "Stress",
    "alpha", "beta", "delta", "gamma", "theta",
    "stability", "medication power", "medication ratio", "medication score",
  ];
  
  return (
    <>
      {!loggedIn && (<Navigate to="/" />)}
      <Header />
      <div className='dashboard'>
        <WSUtil machineID={machineID}/>
        {console.log(machineID)}
        <div className='dashboard__maincard'>


          <div className='dashboard__longcomp'> 
            <SensorChart sensor="eeg" metrics={eegMetrics} />
          </div>


          <div className='dashboard__longcomp'>
            <EEGHeatmap />
          </div>


          {/* <div className='dashboard__longcomp'> 
            <SensorChart sensor="mood" metrics={moodMetrics} />
          </div> */}
          

        </div>
        
        
        
      </div>
    </>
  )
}

export default EEG