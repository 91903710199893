import { useRef, useEffect, useState } from 'react'
import { useFrame } from '@react-three/fiber'

import useStore from '../zustand/useStore'

function usePrinterPosition() {
    const x_target = useStore(state => state.x_target);
    const y_target = useStore(state => state.y_target);
    const z_target = useStore(state => state.z_target);

    const x_prev = useRef(220)
    const y_prev = useRef(220)
    const z_prev = useRef(250)

    const x_start = useRef(0)
    const y_start = useRef(0)
    const z_start = useRef(0)

    const frame = useRef(0)

    // Returned printer position
    const [position, setPosition] = useState([x_target, y_target, z_target])

    // Number of frames over which to interpolate
    // Might need to change if position data is
    // supplied at a frequency other than 1Hz
    const FRAMES = 60;

    // Performs a linear interpolation from s to e with time t in [0, 1]
    function lerp(s, e, t) {
        return s*(1-t) + e*t
    }

    // Called everytime the target position changes
    useEffect(() => {
        // Reset frame counter (used in useFrame)
        frame.current = 0

        // Set animation start position
        x_start.current = x_prev.current
        y_start.current = y_prev.current
        z_start.current = z_prev.current

        // Update snap position for next rerender
        x_prev.current = x_target
        y_prev.current = y_target
        z_prev.current = z_target
    }, [x_target, y_target, z_target])

    // Called every time a frame can be drawn
    useFrame(() => {
        const t = frame.current / FRAMES
        const x_frame = lerp(x_start.current, x_target, t)
        const y_frame = lerp(y_start.current, y_target, t)
        const z_frame = lerp(z_start.current, z_target, t)

        setPosition([x_frame, y_frame, z_frame])

        if (frame.current < FRAMES) {
            frame.current += 1
        }
    })

    return position
}

export default usePrinterPosition
