import { useEffect } from "react";
import useStore from "../../../zustand/useStore";
import Plotly from 'plotly.js-dist';

function EEGHeatmap() {
    const liveData = useStore(state => state.liveData)

    const data = [{
        type: 'contour',
        line: {
            width: 0
        },
        contours: {
            coloring: 'heatmap',
        },
        colorscale: 'Rainbow',
        showscale: false,
        connectgaps: true,
        zmin: -200,
        zmax: 200,
    }]
    
    const layout = {
        xaxis: {
            visible: false,
        },
        yaxis: {
            visible: false,
            autorange: "reversed",
        },
        margin: {
            t: 10,
            l: 0,
            r: 0,
            b: 0,
        },
        paper_bgcolor: "transparent",
        hovermode: false,
    }

    // Spawn the plot on initial render
    useEffect(() => {
        Plotly.newPlot('heatmap', data, layout, {displayModeBar: false, responsive: true})
    }, [])

    // Component rerenders each time new data is available
    useEffect(() => {
        if (liveData === undefined)
            return

        const eeg = liveData.y.eeg
        const z = [
            [null,     null,    eeg.CH1,  null, eeg.CH2,  null,     null],
            [null,     eeg.CH9, null,     null, null,     eeg.CH10, null],
            [null,     null,    eeg.CH11, null, eeg.CH12, null,     null],
            [eeg.CH13, null,    eeg.CH3,  null, eeg.CH4,  null,     eeg.CH14],
            [null,     null,    eeg.CH15, null, eeg.CH16, null,     null],
            [null,     eeg.CH5, null,     null, null,     eeg.CH6,  null],
            [null,     null,    eeg.CH7,  null, eeg.CH8,  null,     null],
        ]

        Plotly.update('heatmap', {z: [z]})
    }, [liveData])

    return (
        <div className="heatmap">
            <div className="comp__header">
                <p>Heatmap</p>
            </div>
            <div id="heatmap" className="charts" style={{aspectRatio: "1 / 1", width: "50%", left: "50%", transform: "translateX(-50%)", clipPath: "circle(45%)", position: "relative"}}/>
        </div>
    )
}

export default EEGHeatmap;
