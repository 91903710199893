// Home page after user has logged in
// Displays all thier machines

import React, {useState, useEffect, useContext, useRef} from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

// Account
import { AccountContext } from '../../context/Account';

// Images 
import printerimg from '../../assets/printer.jpg'
import blenderimg from '../../assets/blender.PNG'
import eegimg from '../../assets/brain.png'

// Components
import Header from '../GlobalComponents/Header';
import SpinnerLoader from '../GlobalComponents/SpinnerLoader';

// Zustand 
import useStore from '../../zustand/useStore';


function HomePage() {
  const loggedIn = JSON.parse(localStorage.getItem('is-login')) || false;
  const { userSub } = useContext(AccountContext);

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  // Zustand States for Machines
  const userMachines = useStore(state => state.userMachines);
  const setUserMachines = useStore((state) => state.setUserMachines)


  const { response, loading } = useAxios({
    method: "get",
    url: `/user/${userSub}`,
  });
  
  // Get users machine's and display in card item
  useEffect(() => {
    if (response !== null) {
      setUserMachines(response.Machines)
    }
  }, [response])

  // img corresponding to the machineType
  const machineImg = {
    '3dprinter': printerimg,
    'blender': blenderimg,
    'eeg': eegimg
  };

  
  return (
    <>
      {!loggedIn && (<Navigate to="/login"/>)}
      <Header />
      {loading ? <SpinnerLoader /> : 
      <div className='generic__page'>
        <div className='header__div'>
          <span className='available__header'>
            Available Machines
          </span>
        </div>

        <div className='home-page'>
          <Link to={`/newgroup`}>
            <button>Add new machine</button>
          </Link>
        </div>

        <div className='home__machines'>
            {/* Get users machine's and display in card item */}
            {userMachines.map((machine) => (
              // need to have a machine type
              <Link to={`/dashboard/${machine.machineType}/${machine.machineID}`} key={machine.machineID}>
                <div className="home__card">
                  <div className='info__overlay'>
                    <h1>{machine.machineName}</h1>
                  </div>
                  <img src={machineImg[machine.machineType]} alt='img of machine'/>
                </div>
              </Link>
            ))}
           
        </div>

      </div>
    }
    </>
  )
}

export default HomePage