import React from 'react'
import useStore from '../../zustand/useStore';

// Componenets
import Header from '../GlobalComponents/Header';

function NotificationPage() {
    const notifications = useStore(state => state.notifications)

    return (
        <>
            <Header />
            <div className='generic__page'>
                <div className='header__div'>
                    <span className='available__header'>
                        Notifications
                    </span>
                </div>


                <div className='notifications'>
                    {/* Get users machine's and display in card item */}
                    {notifications.map((notification) => (
                    // need to have a machine type
                    <div>
                        <h1>MachineID: {notification.machine_id}</h1>
                        <div className="home__card">
                            <div className='info__overlay'>
                                <h1>Message: {notification.message}</h1>
                            </div>
                            <img src={`data:image/jpg;base64,${notification.frame}`} alt='img of machine frame containing error'/>
                        </div>
                    </div>
                    ))}
                
                </div>

            </div>

        </>
    )
}

export default NotificationPage