// create with react-terminal-ui library
// type and send command to machine

import React, {useState, useEffect} from 'react'
import Terminal, {TerminalInput, TerminalOutput } from 'react-terminal-ui';
import useStore from '../../../zustand/useStore';



export const TerminalController = () => {
    const setGCODE = useStore(state => state.setGCODE);
    const commandRespond = useStore(state => state.commandRespond);
    const commands = useStore(state => state.commands);


    const [lineData, setLineData] = useState([
      <TerminalOutput>Send GCODE and MCODE to Printer</TerminalOutput>,
      <TerminalOutput>EX: 'G0 X10;' //Move X-axis by 10mm</TerminalOutput>,
      <TerminalOutput>EX: 'G28;'    //Home all axes </TerminalOutput>,
        
      <TerminalOutput></TerminalOutput>,
      <TerminalOutput className="console">The following commands are provided:</TerminalOutput>,
      <TerminalOutput>'cmds' displays the custom machine commands. </TerminalOutput>,
      <TerminalOutput>'clear' will clear the terminal.</TerminalOutput>,
    ]);
  
    function onInput (input) {
        let ld = [...lineData];
        ld.push(<TerminalInput>{input}</TerminalInput>);
      if (input.toLocaleLowerCase().trim() === 'cmds') {
        if (Object.keys(commands).length === 0) {
            ld.push(<TerminalOutput>No custom commands available.</TerminalOutput>)
        } else {
            ld.push(<TerminalOutput></TerminalOutput>)
            Object.entries(commands).forEach(([commandName, command]) => {
                ld.push(<TerminalOutput>{commandName} - {command.description}</TerminalOutput>)
                ld.push(<TerminalOutput>usage - {command.usage}</TerminalOutput>)
                ld.push(<TerminalOutput></TerminalOutput>)
            });
        }
      } else if (input.toLocaleLowerCase().trim() === 'stop') {
        ld.push(<TerminalOutput>Stopped Printing Process</TerminalOutput>);
      }
      else if (input.toLocaleLowerCase().trim() === 'start') {
        ld.push(<TerminalOutput>Started Printing Process</TerminalOutput>);
      }
      else if (input.trim()[0] === 'G' || input.trim()[0] === 'M' || input.trim().split(" ")[0] in commands) {
          ld.push(<TerminalOutput>SENT: {input.trim()}</TerminalOutput>);
          setGCODE(input.trim())
      } else if (input.toLocaleLowerCase().trim() === 'clear') {
        ld = [];
      }
      else if (input) {
        ld.push(<TerminalOutput>Unrecognized command</TerminalOutput>);
      }
      setLineData(ld);
    }

    useEffect(() => {
      let ld = [...lineData];
      ld.push(<TerminalOutput>{commandRespond}</TerminalOutput>);
      setLineData(ld);
    }, [commandRespond]);

  
    return (
      <div className="terminal" >
        <Terminal name='Printer Terminal' onInput={ onInput }>
          {lineData}
        </Terminal>
      </div>
    )
  };