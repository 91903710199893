import React from 'react'
import {Link} from "react-router-dom";

function Page404() {
  return (
    <div>
      <h1>PAGE404</h1>
      <p>
        This is not the page that you are looking for!
        <br />
        <Link to='/'>Return Home</Link>
      </p>
    </div>
  )
}

export default Page404