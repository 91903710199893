// all code partaing websocket connection
// manages recieved json and outgoing json to and from websocket

import React, { useState, useEffect, useRef, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import useStore from '../zustand/useStore';
import { AccountContext } from '../context/Account';
import { Store } from 'react-notifications-component';

export const WSUtil = ({ machineID }) => {
  // WS State
  const setWSConnState = useStore(state => state.setWSConnState)

  // 3D Printer Animation State
  const setXTarget = useStore(state => state.setXTarget)
  const setYTarget = useStore(state => state.setYTarget)
  const setZTarget = useStore(state => state.setZTarget)
  
  //Movement and Position Data
  const setXAbs = useStore(state => state.setXAbs);
  const setYAbs = useStore(state => state.setYAbs);
  const setZAbs = useStore(state => state.setZAbs);
  const setXBedAcc = useStore(state => state.setXBedAcc);
  const setYBedAcc = useStore(state => state.setYBedAcc);
  const setZBedAcc = useStore(state => state.setZBedAcc);
  const setXExtruder = useStore(state => state.setXExtruder);
  const setYExtruder = useStore(state => state.setYExtruder);
  const setZExtruder = useStore(state => state.setZExtruder);

  // Pressure Data
  const pressure = useStore(state => state.pressure)
  const pushPressure = useStore(state => state.pushPressure)
  const popPressure = useStore(state => state.popPressure)
  const setAvgPressure = useStore(state => state.setAvgPressure)

  // Live Data
  const liveData = useStore(state => state.liveData)
  const pushLiveData = useStore(state => state.pushLiveData)

  // Number of Cameras
  const camIds = useStore(state => state.camIds);
  const setCamIds = useStore(state => state.setCamIds);
  // Cam selected by user
  const selectedCam = useStore(state => state.selectedCam);

  // Video Frame
  const setVideoFrame = useStore(state => state.setVideoFrame);

  // GCODE
  const GCODE = useStore(state => state.GCODE);
  const buttonClickGCode = useStore(state => state.buttonClickGCode);

  // Epoch Time
  const updateTime = useStore(state => state.updateTime);

  // Commands
  const setCommands = useStore(state => state.setCommands);

  // Machine Type
  const setMachineType = useStore(state => state.setMachineType);
  const machineType = useStore(state => state.machineType);

  // Update Terminal with Response
  const setCommandRespond = useStore(state => state.setCommandRespond);
  
  // Update Terminal with Response
  const addNotifications = useStore(state => state.addNotifications);


  /* Get account info and connect to websocket. */
  const { getSession } = useContext(AccountContext);
  const [currentSocketUrl, setCurrentSocketUrl] = useState(null);
  const exeRef = useRef(false);
  useEffect(() => {
    if (exeRef.current) {
      console.log('WebSocket Connected Already');
      return;
    }
    getSession()
      .then(session => {
        const cognitoId = session.sub
        const accessToken = localStorage.getItem(`CognitoIdentityServiceProvider.1k75ho2qv49psn5srsh00vi3a6.${cognitoId}.accessToken`);
        const WS_SOCKET_URL = `wss://stream-digitaltwin.com/user?authorization=${accessToken}`;
        // const WS_SOCKET_URL = `ws://localhost:8080/user?authorization=${accessToken}`;
        setCurrentSocketUrl(WS_SOCKET_URL)
    })
    console.log("Connected");
    exeRef.current = true;
  }, []);
  

  const { sendJsonMessage, readyState, lastMessage } = useWebSocket(
    currentSocketUrl,
    {
      share: true,
      shouldReconnect: () => true,
    }
    // , false    #uncomment to close websockt
  );

  
  const readyStateString = {
    0: 'CONNECTING',
    1: 'OPEN',
    2: 'CLOSING',
    3: 'CLOSED',
  }[readyState];
  setWSConnState(readyState);


  /* Send subscription package along with getting the cameras  */
  const packageRef = useRef(true);
  useEffect(() => {
    if (packageRef && readyState === 1) {
      sendJsonMessage({
        "action": "subscribe",
        "event_source": machineID, //machineID, dummy, fyi1kq9hc8 
        "event_type": "upload_data"
      })
      console.log('Subscription Sent');
      sendJsonMessage({
        "action": "get_connected_cameras",
        "machine_id": machineID
      })
      console.log('Camera Message Sent');
      sendJsonMessage({
        "action": "get_info",
        "machine_id": machineID
      })
      console.log('Get Info Message Sent');
      packageRef.current = false;
      return;
    }
  }, [readyState]);

  // Get Live Feeds
  useEffect(() => {
    if (selectedCam === "") {
      return;
    }
    // Stop all cams first
    {
      camIds.map((cam) => (
        sendJsonMessage({
          "action": "stop_video",
          "machine_id": machineID,
          "camera_id": cam,
        })
      ))
    }
    console.log(`Cleared Old Camera Feeds`);
    /* Get selected camera feed */
    sendJsonMessage({
      "action": "subscribe",
      "event_source": `${machineID}|${selectedCam}`,
      "event_type": "send_frame",
    })
    console.log(`Camera Start Sent`);
    return;
  }, [selectedCam]);

  // Sending GCODE
  useEffect(() => {
    if (GCODE !== "") {
      console.log("WS SEND GCODE", GCODE)
      sendJsonMessage({
        "action": "send_command", // send command
        "machine_id": machineID,
        "command": GCODE
      })
    }
  }, [buttonClickGCode, GCODE])

  // Sending Start
  // useEffect(() => {
  //   if (GCODE !== "") {
  //     console.log("WS SEND GCODE", GCODE)
  //     sendJsonMessage({
//   "action": "start_print",
//   "machine_id": "d972iuf4i3",
//   "print_id": "e8905438-c53b-48a2-8bda-1e1549b38efd"
// })
  //   }
  // }, [GCODE])


  // On new message recived from Server
  useEffect(() => {
    if (lastMessage) {
      // console.log(lastMessage)
      try {
        const wsData = JSON.parse(lastMessage.data);
        const { action } = wsData;
        // console.log("wsData", wsData)

        if (action === "publish") {
            const { event_type } = wsData
            
            
            if (event_type === "send_frame" && wsData.event_source === `${machineID}|${selectedCam}`) {
              // console.log(wsData.event.frame)
              setVideoFrame(wsData.event.frame)
            }
            else if (event_type === "upload_data") {
              // console.log(wsData.event)
              const { data, epoch_time } = wsData.event

              // TODO: Compute this using sample time
              // interval instead of hardcoding "1000"
              const millisecondsPerElement = 1000 / data.length
              const dataFunction = {
                "3D_PRINTER": (epoch_time, data) => {
                  printerAnimationData(epoch_time, data)
                  printerPositionData(epoch_time, data)
                },
                "BLENDER": blenderData,
                "EEG": eegData,
              }[machineType]

              // Exit early if machine type is not known
              if (dataFunction === undefined) {
                return
              }

              // TODO: Render more than one EEG sample
              if (machineType == "EEG" && Array.isArray(data)) {
                data.splice(1)
              }

              // TODO: Push requests onto a queue with target epoch time
              // Consumer thread should track time elapsed during computation
              // and skip elements in the queue if it starts falling behind

              // Call data setting function on each piece of data
              data.forEach((dataElement, i) => {
                setTimeout(() => {
                  dataFunction(epoch_time + i*millisecondsPerElement, dataElement)
                }, i*millisecondsPerElement)
              })
            }
        }
        else if (action === "send_connected_cameras") {
          // console.log(wsData.cameras)
          setCamIds(wsData.cameras);
        }
        else if (action === "send_command_response") {
          const { response } = wsData
          setCommandRespond(response)
        }
        else if (action === 'send_machine_notification') {
          const { machine_id, type, message, time } = wsData
          addNotifications(wsData)
          Store.addNotification({
            title: `${machine_id} Message`,
            message: `${message}\r\nCheck Notification Page for More info\r\nTime:${time} `,
            type: `${type}`,   
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
          });
          
        }
        else if (action === 'send_info') {
            const { info } = wsData
            const commands = info.commands ?? {}
            const machineType = info.machine_type ?? "UNKNOWN"

            setCommands(commands)
            setMachineType(machineType)
        }
      }
      catch (e) {}
    }
  }, [lastMessage]);

  function printerPositionData(epoch_time, data) {
    // console.log(data.current_position.millimeters_x)
    setXAbs( parseFloat(data.current_position.millimeters_x).toFixed(2))
    setYAbs( parseFloat(data.current_position.millimeters_y).toFixed(2))
    setZAbs( parseFloat(data.current_position.millimeters_z).toFixed(2))
    setXBedAcc( parseFloat(data.bed_accelerometer.meters_per_second_squared_x).toFixed(2))
    setYBedAcc( parseFloat(data.bed_accelerometer.meters_per_second_squared_y).toFixed(2))
    setZBedAcc( parseFloat(data.bed_accelerometer.meters_per_second_squared_z).toFixed(2))
    setXExtruder( parseFloat(data.extruder_accelerometer.meters_per_second_squared_x).toFixed(2));
    setYExtruder( parseFloat(data.extruder_accelerometer.meters_per_second_squared_y).toFixed(2));
    setZExtruder( parseFloat(data.extruder_accelerometer.meters_per_second_squared_z).toFixed(2));

    // Time Data
    const date = new Date(epoch_time);
    const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
    const options = { timeZone: timeZone, timeZoneName: 'short' };
    let time = String(date.toLocaleString(locale, options));
    updateTime(time);
  }

  function printerAnimationData(epoch_time, data) {
    // Set position
    const {millimeters_x, millimeters_y, millimeters_z} = data.current_position
    setXTarget(millimeters_x)
    setYTarget(millimeters_y)
    setZTarget(millimeters_z)

    // Cycle pressure list
    const pressureData = parseFloat(data["pressure_sensor"]["grams"]).toFixed(2)
    if (pressure.length > 600) {
      popPressure();
    }
    pushPressure({ x: epoch_time, y: pressureData });

    const task_names = pressure.map(function (data) {
      // console.log("data.y", data.y)
      return parseFloat(data.y).toFixed(2); 
    });
    
    const sum = task_names.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    setAvgPressure((sum/(pressure.length-1)).toFixed(2));
  }

  function blenderData(epoch_time, data) {
    const pressureData = parseFloat(data["pressure_sensor"]["grams"]).toFixed(2)
    if (pressure.length > 600) {
      popPressure();
    }
    pushPressure({ x: epoch_time, y: pressureData });
    const task_names = pressure.map(function (data) {
      // console.log("data.y", data.y)
      return parseFloat(data.y).toFixed(2); 
    });

    const sum = task_names.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    setAvgPressure((sum/(pressure.length-1)).toFixed(2));
  }

  function eegData(epoch_time, data) {
    pushLiveData({ x: epoch_time, y: data })
  }
};
