/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: papysh (https://sketchfab.com/papysh)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/modern-blender-51b6a3efaed14a438a081bc69abc3398
title: Modern Blender
*/

import React, { useRef, Suspense, useEffect } from 'react'
import { useGLTF, OrbitControls } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import MoreVertIcon from '@mui/icons-material/MoreVert';

export function BlenderModel(props) {
  const { nodes, materials } = useGLTF('/Models/blender/scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 45]} scale={2} position={[0,-3,0]}>
        <mesh geometry={nodes.Object_2.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Object_3.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Object_4.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Object_5.geometry} material={materials['Material.003']} />
        <mesh geometry={nodes.Object_6.geometry} material={materials['Material.004']} />
        <mesh geometry={nodes.Object_7.geometry} material={materials['Material.005']} />
      </group>
    </group>
  )
}

const Blender = ( () => {
  return (
    <>
      <div className='comp__header'>
        <p>3D Twin View</p>
        <MoreVertIcon className='icon more__icon'/>
      </div>
      <div className='printer__canvas'>
        <Canvas>
          <Suspense fallback={null}>
            <ambientLight />
            <spotLight intensity={0.9} angle={0.1} penumbra={1}
              position={[10,15,10]} castShadow />

            <BlenderModel />
            <OrbitControls 
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
            />
          </Suspense>
        </Canvas>
      </div>
    </>
  )
});
  
export default Blender