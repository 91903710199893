// x, y, z controller

import React from 'react'

// MUI Icons and Styles
import { styled } from "@mui/material/styles";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HomeIcon from '@mui/icons-material/Home';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MuiToggleButton from '@mui/material/ToggleButton';
import Inventory2 from '@mui/icons-material/Inventory2';
// import PauseIcon from '@mui/icons-material/Pause';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';


import useStore from '../../../zustand/useStore';

import Position from './Position';

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#3E3E3E',
  }
});


function Controller() {
  const x_absolute_position = useStore(state => state.x_absolute_position);
  const y_absolute_position = useStore(state => state.y_absolute_position);
  const z_absolute_position = useStore(state => state.z_absolute_position);
  const step = useStore(state => state.step)
  const setGCODE = useStore(state => state.setGCODE);
  const setButtonClickGCode = useStore(state => state.setButtonClickGCode);
  const toggleProductEnabled = useStore(state => state.toggleProductEnabled);

  const send = (CODE) => {
    let command = `G0 ${CODE} ;`
    console.log("SEND", command)
    setGCODE(command);
    setButtonClickGCode();
  }

  const controlClickHome = (direction) => {
    console.log("SEND Home", direction)
    setGCODE(`G28 ${direction} ;`);
    setButtonClickGCode();
  }

  const setStep = useStore(state => state.setStep)

  const handleLevel = (event, newLevel) => {
    setStep(newLevel);
  };  
    
  const levels = ['1', '2', '5', '10', '15', '20']

  return (
      <>
      <div className='controller'>
          <div className='control__movement'>
              <div className='up controller_btn' onClick={() => send(`Y${(parseInt(y_absolute_position) + parseInt(step))}`)}>
                  <ArrowUpwardIcon />
              </div>
              <div className='left controller_btn' onClick={() => send(`X${(parseInt(x_absolute_position) - parseInt(step))}`)}>
                  <ArrowBackIcon />
              </div>
              <div className='center controller_btn' onClick={() => send("X110 Y110")}>
                  <FiberManualRecordIcon /> 
              </div>
              <div className='right controller_btn' onClick={() => send(`X${(parseInt(x_absolute_position) + parseInt(step))}`)}>
                  <ArrowForwardIcon />
              </div>
              <div className='down controller_btn' onClick={() => send(`Y${(parseInt(y_absolute_position)-parseInt(step))}`)}>
                  <ArrowDownwardIcon />
              </div>
              <div className='home__btn controller_btn' onClick={() => controlClickHome("")}>
                  <HomeIcon /> 
              </div>
              <div className='product__btn controller_btn' onClick={() => toggleProductEnabled()}>
                <Inventory2 />
              </div>
          </div>
          
          <div className='control__zed'>
              <div className='up controller_btn' onClick={() => send(`Z${(parseInt(z_absolute_position) + parseInt(step))}`)}>
                  <ArrowUpwardIcon />
              </div>
              <div className='center controller_btn' onClick={() => controlClickHome('Z')}>
                  <FiberManualRecordIcon />
              </div>
              <div className='down controller_btn' onClick={() => send(`Z${(parseInt(z_absolute_position)-parseInt(step))}`)}>
                  <ArrowDownwardIcon />
              </div>
          </div>
          
          </div>
          <br />

      <div className='levels'>
        <ToggleButtonGroup
        size="small"
        // color="primary"
          value={step}
        exclusive
        onChange={handleLevel}
        aria-label="adjustment levels"
        >
          {levels.map((level) => (
          <ToggleButton key={level} value={level} aria-label={level} style={{"border": "1.7px solid black", 'fontWeight': "bold", 'fontSize': '1.5rem'}}>
            {level}
          </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <Position />
  </>
)
}

function ControlPanel() {
  const style = {
    "display": 'flex',
    "flexDirection": 'row',
    "justifyContent": 'space-between',
    'margin': "2%",
}
  return (
    <div>
      <div className='dashboard__controller'>
        <Controller />
      </div>
      {/* <div style={style}>
          <h3>Printing Progress: ...</h3>
          <div>
            {<PauseIcon />}
          </div>
      </div> */}

      {/* <div className='cancle__btn'>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="plain__btn red cancle__btn"
        onClick={() => (modalOpen? closeModal() : openModal())}
      >
        Cancel Print
        </motion.button>
      </div> */}
    
    </div>
  )
}

export default ControlPanel