// return the status aka email in this case, from the Account.js

import React, {useState, useContext, useEffect} from "react";
import { AccountContext } from './Account';
// import CognitoIdentityServiceProvider from 'aws'

const Status = () => {
    const [userEmail, setStatus] = useState('guest');
    const {getSession} = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                const userType = session.email
                setStatus(userType);
            })
    }, []);


    return (
        <>
            <h1>{userEmail} </h1>
        </>
    )
};

export default Status;