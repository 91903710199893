// Displays sensor data in real time

import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-streaming";

// MUI
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Zustand
import useStore from "../../../zustand/useStore";

function SensorChart({ sensor, metrics }) {
  const chart = useRef()

  const liveData = useStore(state => state.liveData)

  // Component rerenders each time new data is available
  // This pushes data into the chart
  useEffect(() => {
    // Prevent crash on first render
    if (liveData === undefined)
      return

    const ds = chart.current.chartInstance.data.datasets
    metrics.forEach((metric, i) => {
      const point = { x: liveData.x, y: liveData.y[sensor][metric] }
      ds[i].data.push(point)
    })
  }, [liveData])

  const Chart = require("react-chartjs-2").Chart;

  const color = Chart.helpers.color;
  const chartColors = [
    "rgb(255, 0, 0)",
    "rgb(153, 102, 255)",
    "rgb(54, 100, 255)",
    "rgb(0, 155,50)",
    "rgb(255, 205, 86)",
    "rgb(201, 203, 207)",
  ]

  const datasets = metrics.map((metric, i) => ({
    label: metric,
    backgroundColor: color(chartColors[i%chartColors.length])
      .alpha(.5)
      .rgbString(),
    borderColor: color(chartColors[i%chartColors.length])
      .rgbString(),
    fill: false,
    lineTension: 0,
    borderDash: [0, 0],
  }))

  const data = {
    datasets: datasets
  };

  const options = {
    animation: false,
    frameRate: 24,
    layout: {
      margin: {
        bottom: 5,
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: sensor.toUpperCase(),
    },
    scales: {
      xAxes: [
        {
          type: "realtime",
          distribution: "linear",
          realtime: {
            duration: 20000,
            delay: 2000,
          },
          ticks: {
            displayFormats: 1,
            maxRotation: 0,
            minRotation: 0,
            stepSize: 10,
            minUnit: "minute",
            source: "auto",
          }
        }
      ],
    }
  };

  return (
    <div className="lineChart">
        <div className='comp__header'>
            <p>Live Data</p>
            <MoreVertIcon className='icon more__icon'/>
        </div>
      <Line ref={chart} className='charts' data={data} options={options}/>
    </div>
  );
}

export default SensorChart;
