// Zustand state manager
// contains all code partaining to needing state management

import create from 'zustand';

const useStore = create((set) => ({
    // Homepage Track User Machines
    userMachines: [],
    setUserMachines: (machines) => set(() => ({ userMachines: machines })),

    // SidePanel control
    showLiveViewer: true,
    show3DTwin: true,
    showGraph: true,
    showML: false,
    flipLiveViewer: () => set((state) => ({ showLiveViewer: !(state.showLiveViewer) })),
    flip3DTwin: () => set((state) => ({ show3DTwin: !(state.show3DTwin) })),
    flipGraph: () => set((state) => ({ showGraph: !(state.showGraph) })),
    flipML: () => set((state) => ({ showML: !(state.showML) })),

    // WebSocket Connection Status
    wsConnState: 0,
    setWSConnState: (data) => set(() => ({wsConnState: data})),

    // Settings Modal Control
    settingModal: false,
    flipSettingsModal: () => set((state) => ({ settingModal: !(state.settingModal) })),


    // Time
    lastUpdateTime: "--/--/----, --:--:-- -- ---",
    updateTime: (data) => set(() => ({ lastUpdateTime: data })),
    
    // Position for animation
    x_target: 0,
    y_target: 0,
    z_target: 0,
    setXTarget: (data) => set((state) => ({ x_target: data })),
    setYTarget: (data) => set((state) => ({ y_target: data })),
    setZTarget: (data) => set((state) => ({ z_target: data })),
    
    // Absolute Position Data
    x_absolute_position: "0",
    y_absolute_position: "0",
    z_absolute_position: "0",
    x_extruder: 0,
    y_extruder: 0,
    z_extruder: 0,
    x_bedAcc: 0,
    y_bedAcc: 0,
    z_bedAcc: 0,
    setXAbs: (data) => set((state) => ({ x_absolute_position: data })),
    setYAbs: (data) => set((state) => ({ y_absolute_position: data })),
    setZAbs: (data) => set((state) => ({ z_absolute_position: data })),
    setXBedAcc: (data) => set((state) => ({ x_bedAcc: data })),
    setZBedAcc: (data) => set((state) => ({ y_bedAcc: data })),
    setYBedAcc: (data) => set((state) => ({ z_bedAcc: data })),
    setXExtruder: (data) => set((state) => ({ x_extruder: data })),
    setYExtruder: (data) => set((state) => ({ y_extruder: data })),
    setZExtruder: (data) => set((state) => ({ z_extruder: data })),
    // Controller Movement Step
    step: "0.1",
    setStep: (data) => set(() => ({ step: data })),
    // GCODE
    GCODE: "",
    setGCODE: (data) => set(() => ({ GCODE: data })),
    // gcode force update when clicked
    buttonClickGCode: 0,
    setButtonClickGCode: () => set((state) => ({ buttonClickGCode: state.buttonClickGCode + 1})),

    liveData: undefined,
    pushLiveData: (data) => set((state) => ({ liveData: data })),

    // Data for chart
    pressure: [{x: 0, y: 0}],
    pushPressure: (data) => set((state) => ({ pressure: [...state.pressure, data] })),
    popPressure: () => set((state) => ({ pressure: state.pressure.slice(1) })),
    avgPressure: 0,
    setAvgPressure: (data) => set((state) => ({ avgPressure: data })),
    // Number of Cameras Connected
    camIds: [],
    setCamIds: (data) => set(() => ({ camIds: data })),
    selectedCam: "",
    setSelectedCam: (data) => set(() => ({ selectedCam: data })),

    // Controls product twin
    productEnabled: false,
    setProductEnabled: (data) => set(() => ({ productEnabled: data })),
    toggleProductEnabled: () => set((state) => ({ productEnabled: !state.productEnabled })),

    // Connected Sensors
    sensorCount: [],
    setSensorCount: (data) => set(() => ({ sensorCount: data })),

    // Json Video Data from ESP WS
    videoFrame: "",
    setVideoFrame: (data) => set(() => ({ videoFrame: data })),

    // Combine 2D and 3D View
    combineModelView: false,
    setCombineModelView: () => set((state) => ({ combineModelView: !state.combineModelView })),
    
    // Set Response From Machine
    commandRespond: "",
    setCommandRespond: (data) => set(() => ({ commandRespond: data })),

    // Set Commands For Machine
    commands: {},
    setCommands: (cmds) => set(() => ({ commands: cmds })),

    // Set Machine Type
    machineType: "",
    setMachineType: (type) => set(() => ({ machineType: type })),

    // Store Notifcations
    notifications: [{ machine_id:'123', type:'warning', message:'test', time:'time', frame:'img/' }],
    addNotifications: (data) => set((state) => ({ notifications: [...state.notifications, data] }))

}))

export default useStore