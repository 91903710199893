// Display camera feed data in real time

import React from 'react';

// MUI Icons
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import useStore from '../../../zustand/useStore';

function SelectDropDown() {
    const camIds = useStore(state => state.camIds);
    const selectedCam = useStore(state => state.selectedCam);
    const setSelectedCam = useStore(state => state.setSelectedCam);
  
    const handleChange = (event) => {
      setSelectedCam(event.target.value);
    };
  
    return (
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">Camera</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={selectedCam}
          label="Camera"
          onChange={handleChange}
          style={{ "zIndex": "10"}}
        >
          {camIds.map((cam) => (
            <MenuItem
              key={cam}
              value={cam}
            >
              {cam}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

function LiveFeed({ machineID }) {
    const videoFrame = useStore(state => state.videoFrame)

    
    const downloadStyle = {
        "display": 'flex',
        "flexDirection": 'row',
        'alignItems': 'center',
    }
    

    return (
        <div className='livefeed'>
            <div className='livefeed__header'>
                
                <div className='comp__header'>
                    <p>Camera</p>
                    <div style={downloadStyle}>
                        <SelectDropDown />
                        <MoreVertIcon className='icon more__icon'/>
                    </div>
                </div>
            </div>
            <img src={`data:image/jpg;base64,${videoFrame}`} id="frame" className='livefeedvid'></img>
            
        </div>
    )
}

export default LiveFeed