// 3D Printer Dashboard UI composed of components from /Componenets


import React, {useContext, useState, useEffect} from 'react';
import { Navigate, useParams } from 'react-router-dom'

// Account info
import { AccountContext } from '../../../context/Account';

// components
import Header from '../../GlobalComponents/Header'
import Printer from '../Components/3dComponents/Printer';
import LiveFeed from '../Components/LiveFeed';
import LiveChart from '../Components/LiveChart';
import _2DView from '../Components/3dComponents/_2DView';
import ControlPanel from '../Components/ControlPanel';
import {TerminalController} from '../Components/Terminal';

// Zustand state
import useStore from '../../../zustand/useStore'

// WS Util
import { WSUtil } from '../../../utils/wsUtil'


function Printer3D() {
  
  // User account info
  const loggedIn = JSON.parse(localStorage.getItem('is-login')) || false;
  const { machineID } = useParams();
  const [role, setRole] = useState("Viewer");
  const { userSub } = useContext(AccountContext);
  
  return (
    <>
      {!loggedIn && (<Navigate to="/" />)}
      <Header />
      <div className='dashboard'>
        <WSUtil machineID={machineID}/>
        {console.log(machineID)}
        <div className='dashboard__maincard'>

          <div className='dashboard__comp'> 
            <LiveFeed />
          </div>

          <div className='dashboard__comp'> 
            <Printer />
          </div>
          
          <div className='dashboard__comp'> 
            <_2DView />
          </div>

          <div className='dashboard__blankcomp'> 
            <ControlPanel />
          </div>

          <div className='dashboard__blankcomp'> 
            <TerminalController />
          </div>

          <div className='dashboard__longcomp'> 
            <LiveChart />
          </div>

          

        </div>
        
        
        
      </div>
    </>
  )
}

export default Printer3D