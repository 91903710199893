// self made hook, useAxios, utilizes the axios library to make API requests.


import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {AccountContext} from '../context/Account';

// const { REACT_APP_API_URL } = process.env;
axios.defaults.baseURL = 'https://2fomcqj5e6.execute-api.us-east-1.amazonaws.com/test';


const useAxios = ({ method, url, body = null}) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);

    const { userSub } = useContext(AccountContext);

    const cognitoProvider = `CognitoIdentityServiceProvider.1k75ho2qv49psn5srsh00vi3a6.${userSub}.idToken`
    let idToken = localStorage.getItem(cognitoProvider);

    const headers = {
        headers: {
            'Authorization': idToken,
        }
    }
    const fetchData = () => {
        axios[method](url, headers, body)
            .then((res) => {
                console.log(url)
                setResponse(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setloading(false);
            });
    };

    
    useEffect(() => {
        fetchData();
    }, [method, url, body]);

    return { response, error, loading };
};

export default useAxios;