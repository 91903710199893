import React, { useState, useContext } from 'react'
import {Navigate} from "react-router-dom";

// Components
import Console from "./components/Console";
import SpinnerLoader from "../GlobalComponents/SpinnerLoader"

// Account Information
import {AccountContext} from '../../context/Account'
import UserPool from '../../context/UserPool';

function LoginPage() {
  const {authenticate} = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setLogin] = useState(
    JSON.parse(localStorage.getItem('is-login')) || false
  );

  /* Control login or signup form to show */
  const [loginForm, setloginForm] = useState(true);

  if (loggedIn) {
    return <Navigate to="/home" replace />;
  }

  const submitForm = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

    console.log(inputObject.email);
    authenticate(inputObject.email, inputObject.password)
    .then(data => {
      console.log("Logged in!", data);
      localStorage.setItem('is-login', JSON.stringify(true));
      setIsLoading(false);
      setLogin(true);
    })
    .catch(err => {
      console.error("Failed to login", err);
      alert(err);
      setIsLoading(false);
    })
  };

  const submitSignUpForm = (e) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

    UserPool.signUp(inputObject.email, inputObject.password, [], null, (err, data) => {
    if (err) {
      console.error(err);
      setIsLoading(false);
      alert(err);
    }
    setIsLoading(false);
    setloginForm(true);
    console.log(data);
  });
};

  return (
    <div>
      {isLoading ? <SpinnerLoader /> : null}
      <div className='header'>
        <a href="https://stream.eng.buffalo.edu/" >
          <img className="header__logo" src={process.env.PUBLIC_URL + `/images/logo.png`} alt="logo"/>
        </a>
      </div>
      <div className='login'>
        {loginForm ? 
        <div className='login__form'>
          <h1>Login</h1>
          <form onSubmit={submitForm}>
            <div className='login__input'>
              <input name="email" placeholder="Email" type="email" value={"DEMO@USER.com"}/>
            </div>
            <div className='login__input'>
              <input name="password" placeholder="Password" type="password" value={"@Test1234"}/>
            </div>
            <br />
            <button type="submit" className='login__input'>Log In</button>
            <button onClick={() => setloginForm(false)} className='login__input'>Need an account? Sign up</button>
          </form>
        </div>
          :
          <div className='login__form'>
            <h1>Sign Up</h1>
            <form onSubmit={submitSignUpForm}>
              <div>
                <input name="fullname" placeholder="Full Name" type='text'/>
              </div>
              <div>
                <input name="email" placeholder="Email" type='email'/>
              </div>
              <div>
                <input name="password" placeholder="Password" type='password'/>
              </div>
              <br />
              <button type="submit">Sign Up</button>
              <button onClick={() => setloginForm(true)}>Already have an account? Log In</button>
            </form>
          </div>
        }
        <Console />
      </div>
    </div>
  )
}
  
export default LoginPage;
