import React, { useRef, Suspense, useEffect } from 'react'
import { useGLTF, OrbitControls } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { DoubleSide } from "three";

import usePrinterPosition from '../../../../hooks/usePrinterPosition'

// MUI Icon
import MoreVertIcon from '@mui/icons-material/MoreVert';

const BED_SIZE = 220

function Nozzle({ ...props }) {
  const nozzle = useRef()

  const [x, y, _] = usePrinterPosition()

  useEffect(() => {
    nozzle.current.position.x = (x / BED_SIZE * 22 - 11)
    nozzle.current.position.z = (11 - y / BED_SIZE * 22)
  }, [x, y])

  const { nodes, materials } = useGLTF('/Models/nozzle.glb')
  return (
    <group ref={nozzle} {...props} dispose={null} position={[-11,0,11]} scale={.01}>
      <mesh geometry={nodes['04mm-Entity65806'].geometry} material={materials['Material.005']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Component#9-Entity65807'].geometry} material={materials['Material.006']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Component#11-Entity82134'].geometry} material={materials['Material.006']} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['04mm-Entity65811'].geometry} material={materials.FrontColor} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['04mm-Entity65815'].geometry} material={materials.FrontColor} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['04mm-Entity65812'].geometry} material={materials.FrontColor} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['04mm-Entity65814'].geometry} material={materials.FrontColor} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['04mm-Entity65813'].geometry} material={materials.FrontColor} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

function Plane() {
  return (
    <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={22}>
      <planeBufferGeometry />
      <meshBasicMaterial color="white" side={DoubleSide} />
    </mesh>
  );
}


function _2DView() {
  return (
    <div className='printer__canvas'>
      <div className='comp__header'>
        <p>2D View</p>
        <MoreVertIcon className='icon more__icon'/>
      </div>
        <Canvas camera={{
          position: [0, 15, 0]
        }}>
          <Suspense fallback={null}>
            <ambientLight />
            <Plane />
            <Nozzle />
            <gridHelper args={[22, 22, `black`, `gray`]}/>.
          </Suspense>
        </Canvas>
      </div>
    );
    
}

export default _2DView