// Displays live data in real time

import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-streaming";

// MUI
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import DownloadIcon from '@mui/icons-material/Download';
// import PauseIcon from '@mui/icons-material/Pause';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
// import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
// import AddIcon from '@mui/icons-material/Add';
// import TempTable from "./TempTable";

// Zustand
import useStore from "../../../zustand/useStore";

function LiveChart() {
  const [zoom, setZoom] = useState(false);
  const [pause, setPause] = useState(false);

  const pressure = useStore(state => state.pressure)

  const Chart = require("react-chartjs-2").Chart;

  const color = Chart.helpers.color;
  const chartColors = {
    red: "rgb(255, 0, 0)",
    purple: "rgb(153, 102, 255)",
    blue: "rgb(54, 100, 255)",
    green: "rgb(0, 155,50)",
    yellow: "rgb(255, 205, 86)",
    grey: "rgb(201, 203, 207)"
  };
  const data = {
    datasets: [
      {
        label: "Pressure", //vairable name
        backgroundColor: color(chartColors[0])
          .alpha(.1)
          .rgbString(),
        borderColor: chartColors.purple,
        fill: false,
        lineTension: 0,
        borderDash: [0, 0],
        data: pressure
      },
    ]
  };
  const options = {
    layout: {
      margin: {
        bottom: 5,
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: "Temp ℃",
    },
    scales: {
      xAxes: [
        {
          type: "realtime",
          distribution: "linear",
          realtime: {
            duration: 50000,
            delay: 1000,
            pause: pause,
          },
          ticks: {
            displayFormats: 1,
            maxRotation: 0,
            minRotation: 0,
            stepSize: 10,
            // maxTicksLimit: 30,
            minUnit: "minute",
            source: "auto",
            // autoSkip: true,
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // max: 5
          }
        }
      ]
    }
  };

  return (
    <div className="lineChart">
      {/* <div className='chart__header'>
            <div >
            <AddIcon className="chart__icon"/>
            {zoom ? <ZoomInOutlinedIcon className="chart__icon"/> : <ZoomOutOutlinedIcon className="chart__icon"/> }
            <CalendarMonthOutlinedIcon className="chart__icon"/>
            <AddIcon className="chart__icon"/>
            <DownloadIcon className="chart__icon"/>
            </div>
        </div> */}
        <div className='comp__header'>
            <p>Live Data</p>
            <MoreVertIcon className='icon more__icon'/>
        </div>
      {/* <TempTable /> */}
      <Line className='charts' data={data} options={options}/>
      {/* <button onClick={() => { setPause(!pause) }}>
        <PauseIcon />
      </button> */}
    </div>
  );
}

export default LiveChart;
